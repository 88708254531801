@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes slide-bar {
    from {
        width: 100%;
    }
    to {
        width: 0%;
    }
}

//components
@import "forms";

.animate-slide-bar {
    animation: slide-bar 10s linear forwards;
}

.new-version-shop{
    position: fixed;
    bottom: 0;
    z-index: 99999;
    .row {
        background-color: #f5f5f5;
        margin: 0 15px 15px 15px;
    }
    p {
        padding: 15px 15px 15px 0;
        margin: 0;
    }
    .new-version-shop-close-div {
        margin: auto;
    }
    .new-version-shop-close-svg {
        cursor: pointer;
        color: #000;
    }
}


.ch-cookie-consent__category-toggle-disable input + label {
    background-repeat: no-repeat;
    background-position: 45px center;
    background-color: #e0e0e0; /* Changed to gray background */
    color: #888888; /* Changed to lighter text color */
    border: 1px solid #cccccc; /* Changed to lighter border color */
    transition: all 0.2s;
    display: inline-block;
    margin-right: 15px;
    padding: 6px 28px 6px 10px;
    cursor: not-allowed; /* Changed cursor to indicate disabled state */
    border-radius: 6px;
    width: 75px;
    opacity: 0.7; /* Added opacity to enhance disabled appearance */
}

.ch-cookie-consent__category-toggle-disable input + label::before, .ch-cookie-consent__category-toggle-disable input + label::after {
    content: '';
}

.ch-cookie-consent__category-toggle-disable input {
    opacity: 0;
    position: absolute;
    left: -10000px;
}


.ch-cookie-consent__category-toggle-disable input:checked + label,
.ch-cookie-consent__category-toggle-disable input  {
    background-color: #223462; /* Kept consistent with disabled state */
    color: #fff; /* Kept consistent with disabled state */
    border-color: #cccccc; /* Kept consistent with disabled state */
}
